/* .tag-cloud {
  border: 1px solid blue;
} */

h1 {
  margin: 20px auto;
  text-align: center;
}

.tag-cloud {
  text-align: center;
}

.tag-cloud .tag-cloud-tag {
  border: 1px solid gray;
  background-color: gray;
  padding: 5px;
  border-radius: 3px;
  margin: 5px !important;
  cursor: pointer;
}